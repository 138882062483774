import React, { useCallback } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { colors, spacing, toRem } from '@styles/Theme';
import { H3 } from '@styles/Typography';

import bg from '@images/bg-404.svg';

import { Wrapper, Section } from '@components/Layout/styles';
import Button, { ButtonGroup } from '@components/Button';
import SEO from '@components/seo';

const Container = styled.div`
	max-width: ${toRem(780)};
	margin: 0 auto ${spacing(5)};
	text-align: center;

	img {
		max-width: ${toRem(580)};
	}

	h3 {
		color: ${colors.primary};
	}

	a {
		white-space: nowrap;
	}
`;

const NotFoundPage = () => {
	const { pageData } = useStaticQuery(graphql`
		{
			pageData: allCosmicjsPages(filter: { slug: { eq: "page-not-found" } }) {
				nodes {
					metadata {
						content
					}
				}
			}
		}
	`);

	const { content } = pageData.nodes[0].metadata;

	const handleBack = useCallback(e => {
		e.preventDefault();

		if (window.history.length === 1) {
			navigate('/');

			return;
		}

		window.history.back();
	}, []);

	return (
		<Section>
			<SEO
				title="404: Página não encontrada"
				desription="404: Página não encontrada"
			/>

			<Wrapper>
				<Container>
					<img src={bg} alt="404 - Fleury Infusões" />

					<H3>Página não encontrada</H3>

					<div dangerouslySetInnerHTML={{ __html: content }} />
				</Container>

				<ButtonGroup>
					<Button onClick={handleBack}>Voltar</Button>
					<Button outlined to="/">
						Home
					</Button>
				</ButtonGroup>
			</Wrapper>
		</Section>
	);
};

export default NotFoundPage;
